import styled from "@emotion/styled";
import { ALWAYS_ON_TOP, LAYER_ONE, LAYER_TWO } from "constants/z-index";
const IMAGE_DEFAULT_MAX_WIDTH = "900px";
const DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE = "10vw";
export const StyledTagsAndAssetsComponent = styled.div`
  position: relative;
  width: fit-content;

  .use-cases-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: ${LAYER_TWO};
    position: absolute;
    top: ${({ useCasesTopMargin })=>useCasesTopMargin || "calc(132 * (100vw / 1920))"};

    ${({ flipUseCasesAndAssets })=>flipUseCasesAndAssets ? "right: 0;" : "left: 0;"}
    &.breathe {
      animation: breathe 0.3s linear;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
    max-width: ${({ imageMaxWidth })=>imageMaxWidth || IMAGE_DEFAULT_MAX_WIDTH};
  }

  .default-asset {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.active {
      display: block;
      z-index: ${ALWAYS_ON_TOP};
      opacity: 1;
    }
  }

  .assets-container {
    z-index: ${LAYER_ONE};
    position: relative;
    width: fit-content;
    min-width: ${({ imageMaxWidth })=>imageMaxWidth};

    ${({ flipUseCasesAndAssets, assetsAndTagsExtraSpace })=>flipUseCasesAndAssets ? `margin-right: ${assetsAndTagsExtraSpace || DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE};` : `margin-left: ${assetsAndTagsExtraSpace || DEFAULT_ASSETS_AND_TAGS_EXTRA_SPACE};`}
    .picture-wrapper {
      width: fit-content;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease-in-out;

      &.selected {
        opacity: 1;
      }
    }
  }

  @keyframes breathe {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;
