import styled from "@emotion/styled";
import Colors from "styles/colors";
import { GAINSBORO_COLOR_NAME } from "styles/color-consts";
export const StyledClickableTagsGrid = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	padding: 24px 16px 16px;
	gap: 20px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 40%, rgba(240, 243, 255, 0.3) 80%);
	box-shadow: 3.977px 3.977px 34.8px 0 rgba(0, 0, 0, 0.15);
	border-radius: 18px;
	border: 1px solid ${Colors[GAINSBORO_COLOR_NAME]};
	backdrop-filter: blur(24px);

	.tags-container {
		width: fit-content;
		display: grid;
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 12px;
	}
`;
